import { z } from 'zod'
import {
  BusinessModel,
  EcosystemUser,
  MissionVision,
  ProfileDetails,
  Statements,
  ValueCapture,
  ValueCreation,
} from './token-profile.types'
import {
  DemandDriver,
  DemandDriverEstimation,
  DistributionGroup,
  EpochBasedEmissionGroup,
  FullInitialUnlockGroup,
  LockVestEmissionGroup,
} from './modeling.types'
import { ComparableConfigurations, ProjectDetails } from './valuation.types'
import { EmptyField, SimulatingPostTGEPop } from './adjusting.types'

export * from './token-profile.types'
export * from './modeling.types'
export * from './valuation.types'
export * from './dashboard.types'
export * from './calculator.types'
export * from './public-page.types'
export * from './adjusting.types'

const Values = z
  .union([
    ProfileDetails,
    Statements,
    MissionVision,
    ValueCreation,
    BusinessModel,
    z.array(EcosystemUser),
    ValueCapture,
    z.array(DistributionGroup),
    z.array(LockVestEmissionGroup),
    z.array(EpochBasedEmissionGroup),
    z.array(FullInitialUnlockGroup),
    z.array(DemandDriver),
    z.array(DemandDriverEstimation),
    ComparableConfigurations,
    ProjectDetails,
    SimulatingPostTGEPop,
    EmptyField,
  ])
  .nullable()

export type SectionValues = z.infer<typeof Values>

export const Field = z.object({
  id: z.string().nullable(),
  label: z.string(),
  slug: z.string(),
  subSectionId: z.string().nullable(),
  completed: z.boolean(),
  values: Values,
})
export type Field = z.infer<typeof Field>

export const SubSection = z.object({
  id: z.string().nullable(),
  label: z.string(),
  slug: z.string(),
  sectionId: z.string().nullable(),
  completed: z.boolean(),
  fields: z.array(Field),
  resources: z.array(z.object({ path: z.string(), title: z.string() })).optional(),
})
export type SubSection = z.infer<typeof SubSection>

export const Section = z.object({
  id: z.string().nullable(),
  label: z.string(),
  slug: z.string(),
  tokenDesignerId: z.string().nullable(),
  completed: z.boolean(),
  subSections: z.array(SubSection),
})
export type Section = z.infer<typeof Section>

export const TokenDesigner = z.object({
  id: z.string().nullable(),
  label: z.string(),
  slug: z.string(),
  projectId: z.string().nullable(),
  completed: z.boolean().default(false),
  sections: z.array(Section),
  template: z.string().nullable(),
  FDV: z.number().nullable().optional(),
  MTS: z.number().nullable().optional(),
  AIC: z.number().nullable().optional(),
  referenceProjects: z.array(z.string()),
})
export const TokenDesignerSectionCompletedStatus = z.object({
  completed: z.boolean().default(false),
  error: z.string().nullable().optional(),
})
export type TokenDesigner = z.infer<typeof TokenDesigner>

export const TokenDesigners = z.array(TokenDesigner)
export type TokenDesigners = z.infer<typeof TokenDesigners>

export const ReferenceTokenDesigner = z.object({
  name: z.string(),
  image: z.string().nullable(),
  FDV: z.number().optional(),
  MTS: z.number().optional(),
  AIC: z.number().optional(),
  values: z.array(
    z.object({
      slug: z.string(),
      values: z.union([
        z.array(DistributionGroup).nullable(),
        z.array(DemandDriver).nullable(),
        ComparableConfigurations.nullable(),
        z.any(), // TODO: need to reuse Values
      ]),
    }),
  ),
})
export type ReferenceTokenDesigner = z.infer<typeof ReferenceTokenDesigner>

export const ReferenceTokenDesigners = z.array(ReferenceTokenDesigner)
export type ReferenceTokenDesigners = z.infer<typeof ReferenceTokenDesigners>

/**
 * Finding Product Market Fit
 */

/**
 * Reference Projects
 *
 * See https://docs.google.com/spreadsheets/d/12fY5Wu-xSyO6V24Sy0k1QItX4PBX5Dsv1sxShza0xb4/edit#gid=184020401
 *
 * Also see apps/api/src/app/token-designer/templates/templates.json for a snapshot we can use for dev
 *
 * We'll need these in the DB, as users will be selecting similar projects in the first step of Token Designer
 */

export const ReferenceProject = z.object({
  title: z.string(),
  ticker: z.string(),
  shortDescription: z.string(),
  sector: z.string(),
  problem: z.string(),
  solution: z.string(),
  mission: z.string(),
  vision: z.string(),
  valueCreation: z.string(),
  revenueFrom: z.string(),
  revenueDenominatedIn: z.string(),
  revenueTo: z.string(),
  valueCaptureToken: z.string(),
  valueCaptureProtocol: z.string(),
  demandDrivers: z.array(z.string()),
  tokenUtility: z.string(),
  coingeckoApiKey: z.string(),
  price: z.number(),
  marketCap: z.number(),
  fdv: z.number(),
  volume: z.number(),
  diagramUrl: z.string(),
  parent: z.string().nullable(),
  strongPoints: z.string().nullable(),
  weakPoints: z.string().nullable(),
  image: z.string(),
})

export const ReferenceProjects = z.array(ReferenceProject)
export type ReferenceProject = z.infer<typeof ReferenceProject>
export type ReferenceProjects = z.infer<typeof ReferenceProjects>

export const ReferenceProjectInfo = z.object({
  id: z.string().uuid(),
  name: z.string().nullable(),
  ticker: z.string().nullable(),
  image: z.string().nullable(),
  problem: z.string().nullable(),
  solution: z.string().nullable(),
  revenueDenominatedIn: z.string().nullable(),
  revenueFrom: z.string().nullable(),
  mission: z.string().nullable(),
  vision: z.string().nullable(),
  valueCaptureProtocol: z.string().nullable(),
  valueCaptureToken: z.string().nullable(),
  valueCreation: z.string().nullable(),
  revenueTo: z.string().nullable(),
  summary: z.string().nullable(),
})
export type ReferenceProjectInfo = z.infer<typeof ReferenceProjectInfo>

export const TokenDesignerTemplates = z.enum(['defi', 'depin', 'base'])
export type TokenDesignerTemplates = z.infer<typeof TokenDesignerTemplates>

export const LoadTDTemplate = z.object({
  template: TokenDesignerTemplates,
})
