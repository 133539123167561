import {
  MarketMakerQuoteKpiMetricEnumValues,
  MarketMakerQuoteKpiMetricEnum,
  type Database,
  type MarketMakerQuoteKpiMetricEnumType,
} from '@forgd/supabase'
import type { PartialWithPick } from '../types'
import { meanBy } from '../utils/numbers'

export const KpiTierEnum = {
  minValue: 'minValue',
  valueTier3Exchanges: 'valueTier3Exchanges',
  valueTier2Exchanges: 'valueTier2Exchanges',
  valueTier1Exchanges: 'valueTier1Exchanges',
  maxValue: 'maxValue',
} as const

export const KpiTierEnumValues = [
  'minValue',
  'valueTier3Exchanges',
  'valueTier2Exchanges',
  'valueTier1Exchanges',
  'maxValue',
] as const

export type KpiTierEnumType = (typeof KpiTierEnum)[keyof typeof KpiTierEnum]

type KpiRow = Database['core']['Tables']['market_maker_quotes_kpis']['Row']
export type KpiRowWithNecessaryFields = PartialWithPick<
  KpiRow,
  | 'metric'
  | 'maxValue'
  | 'minValue'
  | 'minMaxUnit'
  | 'valueTier1Exchanges'
  | 'valueTier2Exchanges'
  | 'valueTier3Exchanges'
>

export const metricEnumToFieldMap = {
  [MarketMakerQuoteKpiMetricEnum.LoanValueDepth50Bps]: 'loanValueDepth50Bps',
  [MarketMakerQuoteKpiMetricEnum.LoanValueDepth100Bps]: 'loanValueDepth100Bps',
  [MarketMakerQuoteKpiMetricEnum.LoanValueDepth200Bps]: 'loanValueDepth200Bps',
  [MarketMakerQuoteKpiMetricEnum.BidAskSpreadBps]: 'bidAskSpreadBps',
} as const

export const KpiTiersLabelMap: Record<string, { label: string; tooltipHTML: string }> = {
  [KpiTierEnum.minValue]: {
    label: 'Minimum Value',
    tooltipHTML:
      'Input minimum values that you will be obligated to provide, regardless of token price or exchange listing achievement. This will prevent unreasonable KPI targets in the event of significant token price movements.',
  },
  [KpiTierEnum.valueTier1Exchanges]: {
    label: 'Tier 1 Exchanges',
    tooltipHTML:
      'If the token is listed on Binance or OKX, it is considered “Tier 1” and you will be obligated to meet these liquidity KPIs.<br><br>NOTE: These values represent coverage across all exchanges. Distribution of liquidity is at your discretion.',
  },
  [KpiTierEnum.valueTier2Exchanges]: {
    label: 'Tier 2 Exchanges',
    tooltipHTML:
      'If the token is listed on Coinbase, ByBit, Kucoin, or Gate and NOT listed on Binance or OKX, it is considered “Tier 2” and you will be obligated to meet these liquidity KPIs.<br><br>NOTE: These values represent coverage across all exchanges. Distribution of liquidity is at your discretion.',
  },
  [KpiTierEnum.valueTier3Exchanges]: {
    label: 'Tier 3 Exchanges',
    tooltipHTML:
      'If the token is not listed on Binance, OKX, Coinbase, ByBit, Kucoin, or Gate, it is considered “Tier 3” and you will be obligated to meet these liquidity KPIs.<br><br>NOTE: These values represent coverage across all exchanges. Distribution of liquidity is at your discretion.',
  },
  [KpiTierEnum.maxValue]: {
    label: 'Maximum Value',
    tooltipHTML:
      'Input maximum values that you will be obligated to provide, regardless of token price or exchange listing achievement. This will prevent unreasonable KPI targets in the event of significant token price movements.',
  },
}

const calculateKpiTableCell = (
  value: number,
  metric: MarketMakerQuoteKpiMetricEnumType,
  tier: KpiTierEnumType,
  loanSizeUsd: number,
) => {
  // percent view the unit is percent except:
  // - for minValue and maxValue the unit is usd except for bid_ask_spread_bps which is percent
  const valueUnit =
    metric !== MarketMakerQuoteKpiMetricEnum.BidAskSpreadBps &&
    (tier === KpiTierEnum.minValue || tier === KpiTierEnum.maxValue)
      ? 'usd'
      : 'percent'

  // usd view:
  // - for bid_ask_spread_bps we display just the percent average value
  // - for other metrics:
  //   - if the unit is usd we display usd value as is
  //   - if the unit is percent we calculate the usd value based on the fdv
  const valueUsd =
    metric === MarketMakerQuoteKpiMetricEnum.BidAskSpreadBps || valueUnit === 'usd'
      ? value
      : (value / 100) * loanSizeUsd

  // usd view: unit is usd except for bid_ask_spread_bps which is percent
  const valueUsdUnit = metric === MarketMakerQuoteKpiMetricEnum.BidAskSpreadBps ? 'percent' : 'usd'

  return {
    metric,
    views: {
      percent: { value: value, unit: valueUnit },
      usd: { value: valueUsd, unit: valueUsdUnit },
    },
  }
}

export const getKpiValue = (
  kpis: KpiRowWithNecessaryFields[],
  metric: MarketMakerQuoteKpiMetricEnumType,
  tier: KpiTierEnumType,
) => {
  const kpi = kpis.find((kpi) => kpi.metric === metric)

  if (kpi === undefined) {
    throw new Error(`Market Maker quote kpi metric not found: ${JSON.stringify({ kpis, metric })}`)
  }

  return kpi[tier]
}

export const getKpiTableView = (kpis: KpiRowWithNecessaryFields[], loanSizeUsd: number) => {
  const prepare = (tier: KpiTierEnumType) => {
    return MarketMakerQuoteKpiMetricEnumValues.map((metric) =>
      calculateKpiTableCell(getKpiValue(kpis, metric, tier), metric, tier, loanSizeUsd),
    )
  }

  const minValue = prepare(KpiTierEnum.minValue)
  const valueTier3Exchanges = prepare(KpiTierEnum.valueTier3Exchanges)
  const valueTier2Exchanges = prepare(KpiTierEnum.valueTier2Exchanges)
  const valueTier1Exchanges = prepare(KpiTierEnum.valueTier1Exchanges)
  const maxValue = prepare(KpiTierEnum.maxValue)

  return [
    { tier: KpiTierEnum.minValue, data: minValue },
    { tier: KpiTierEnum.valueTier3Exchanges, data: valueTier3Exchanges },
    { tier: KpiTierEnum.valueTier2Exchanges, data: valueTier2Exchanges },
    { tier: KpiTierEnum.valueTier1Exchanges, data: valueTier1Exchanges },
    { tier: KpiTierEnum.maxValue, data: maxValue },
  ]
}

export const getAvgKpiTableView = (
  kpisGroupedByQuotes: Array<{
    kpis: KpiRowWithNecessaryFields[]
    loanTokenSizeUsd: number
  }>,
) => {
  const calculateAverages = (tier: KpiTierEnumType) => {
    return MarketMakerQuoteKpiMetricEnumValues.map((metric) => {
      // For min/max values and bid_ask_spread_bps, average the raw values
      if (
        tier === KpiTierEnum.minValue ||
        tier === KpiTierEnum.maxValue ||
        metric === MarketMakerQuoteKpiMetricEnum.BidAskSpreadBps
      ) {
        const avgValue = meanBy(kpisGroupedByQuotes, (quote) => getKpiValue(quote.kpis, metric, tier)) || 0
        return calculateKpiTableCell(avgValue, metric, tier, avgValue)
      }

      // For exchange tiers, first calculate both percent and USD values for each quote
      const quoteValues = kpisGroupedByQuotes.map((quote) => {
        const percentValue = getKpiValue(quote.kpis, metric, tier)
        const usdValue = (percentValue / 100) * quote.loanTokenSizeUsd
        return { percentValue, usdValue }
      })

      // Then calculate averages separately
      const avgPercentage = meanBy(quoteValues, ({ percentValue }) => percentValue) || 0
      const avgUsd = meanBy(quoteValues, ({ usdValue }) => usdValue) || 0

      return {
        metric,
        views: {
          percent: { value: avgPercentage, unit: 'percent' },
          usd: { value: avgUsd, unit: 'usd' },
        },
      }
    })
  }

  return [
    { tier: KpiTierEnum.minValue, data: calculateAverages(KpiTierEnum.minValue) },
    { tier: KpiTierEnum.valueTier3Exchanges, data: calculateAverages(KpiTierEnum.valueTier3Exchanges) },
    { tier: KpiTierEnum.valueTier2Exchanges, data: calculateAverages(KpiTierEnum.valueTier2Exchanges) },
    { tier: KpiTierEnum.valueTier1Exchanges, data: calculateAverages(KpiTierEnum.valueTier1Exchanges) },
    { tier: KpiTierEnum.maxValue, data: calculateAverages(KpiTierEnum.maxValue) },
  ]
}
