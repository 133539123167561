import { z } from 'zod'
import { MarketMakerEngagementsStatusEnumValues } from '@forgd/supabase'
import { MarketMakerSchema } from '../market-maker'
import { stringToIntNumber } from '@forgd/shared/utils/zod'
import { EngagementOptionEnumValues } from '@forgd/supabase'

export const EngagementHistorySchema = z.object({
  marketMaker: MarketMakerSchema.pick({
    id: true,
    name: true,
    imageUrl: true,
  }),
  engagement: z.object({
    id: z.string(),
    status: z.enum(MarketMakerEngagementsStatusEnumValues),
    firstEngagementDate: z.date().nullable(),
    lastEngagementDate: z.date().nullable(),
    totalEngagements: z.number().nullable(),
  }),
})

export type EngagementHistory = z.infer<typeof EngagementHistorySchema>

// Get Project Engagements Details
export const GetEngagementsHistoryResult = z.array(EngagementHistorySchema)
export type GetEngagementsHistoryResultType = z.infer<typeof GetEngagementsHistoryResult>

export const GetDashboardDataQuery = z.object({
  date: z.string().date(),
  marketMakerId: z.string().optional(),
  exchange: z.string().optional(),
  orderBook: z.string().optional(),
  daysLookback: z
    .string()
    .optional()
    .transform((val) => {
      return stringToIntNumber(val) ?? undefined
    })
    .refine((val) => val === undefined || val > 0, {
      message: 'daysLookback must be a positive integer',
    }),
})

export type GetDashboardDataQuery = z.infer<typeof GetDashboardDataQuery>

export const PerformanceInsightsSchema = z.object({
  makerTakerPercentage: z.number(),
  allMmBidAsk200bpsPercentage: z.number(),
  avgSpread: z.number(),
})

export const PerformanceSummarySchema = z.array(
  z.object({
    marketMakerId: z.string(),
    projectId: z.string().uuid(),
    typeOfMM: z.preprocess((val) => (typeof val === 'string' ? val : String(val)), z.enum(EngagementOptionEnumValues)),
    bidAskAvgKpi: z.number(),
    depth50BpsAvgKpi: z.number(),
    depth100BpsAvgKpi: z.number(),
    depth200BpsAvgKpi: z.number(),
    fillVolume: z.number(),
    marketMaker: z.preprocess(
      (val) => {
        if (typeof val === 'string') {
          return JSON.parse(val)
        }
        return val
      },
      z.object({
        id: z.string(),
        name: z.string(),
        imageUrl: z.string().nullable(),
      }),
    ),
  }),
)

export const KPIDataSchema = z.object({
  activeDex: z.number(),
  activeCex: z.number(),
  avg24hVolume: z.number().nullable(),
  avgDepth: z.number().nullable(),
  spread: z.number().nullable(),
})

export const GetDashboardDataResult = z.object({
  performanceInsights: PerformanceInsightsSchema.nullable(),
  performanceSummary: PerformanceSummarySchema,
  kpiData: KPIDataSchema.nullable(),
})

export type GetDashboardDataResultType = z.infer<typeof GetDashboardDataResult>
