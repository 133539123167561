export const areFloatsEqual = (a: number, b: number, epsilon = Number.EPSILON) => {
  return Math.abs(a - b) <= epsilon
}

export const isFloatLessWithPrecision = (a: number, b: number, precision = 10) => {
  const factor = Math.pow(10, precision)
  return Math.round(a * factor) < Math.round(b * factor)
}

export const isFloatGreaterWithPrecision = (a: number, b: number, precision = 10) => {
  const factor = Math.pow(10, precision)
  return Math.round(a * factor) > Math.round(b * factor)
}

export const round = (a: number, precision = 10) => {
  const factor = Math.pow(10, precision)
  return Math.round(a * factor) / factor
}

export const meanBy = <T>(array: T[], iteratee: (value: T) => number) => {
  return (
    array.reduce((acc, value) => {
      return acc + iteratee(value)
    }, 0) / array.length
  )
}
