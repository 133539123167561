import { z } from 'zod'
import { customDateSchema } from '../common'
import { MetaSchema } from '../calculator'
import { DistributionGroup, TokenDistributionPreview, TokenEmissionPreview } from '../token-designer'

export const PriceDataSchema = z.object({
  date: z.string(),
  value: z.number(),
})

export const TokenInfoResult = z.object({
  coingeckoId: z.string().nullable(),
  coingeckoInfo: z
    .object({
      id: z.string(),
      name: z.string(),
      symbol: z.string(),
      image: z.string(),
    })
    .nullable(),
  maxTokenSupply: z.number(),
  tgeDate: z.string().nullable(),
})

export type TokenInfo = z.infer<typeof TokenInfoResult>

export const TokenDetailsResponse = TokenInfoResult.merge(
  z.object({
    groups: z.array(DistributionGroup),
    distribution: TokenDistributionPreview,
    emissions: TokenEmissionPreview.nullable(),
    ticker: z.string(),
    daysSinceTGE: z.number(),
    price: z.number(),
    priceChangePercentage24h: z.number(),
    isCoingeckoIdSet: z.boolean(),
    fullyDilutedValuation: z.number(),
    marketChartPrice24h: z.object({
      data: z.array(PriceDataSchema),
      meta: MetaSchema,
    }),
    circulatingTokenSupply: z.number(),
    marketCap: z.number(),
  }),
)

export type TokenDetails = z.infer<typeof TokenDetailsResponse>

export const TokenDetailsToUpdateBody = z.object({
  tgeDate: customDateSchema,
  coingeckoId: z.string().nullable(),
  maxTokenSupply: z.number(),
})

export type TokenDetailsToUpdate = z.infer<typeof TokenDetailsToUpdateBody>
