import { z } from 'zod'
import {
  EngagementOptionEnumValues,
  MarketMakerVolumeTypeEnum,
  MarketMakerVolumeTypeEnumValues,
  ExchangeMcapTierEnumValues,
  ExchangeTierEnumValues,
} from '@forgd/supabase'
import {
  DepthLevelOptionEnum,
  DepthLevelOptionEnumValues,
  LiquidityTypeOptionEnum,
  LiquidityTypeOptionEnumValues,
  MMLeaderboardFilterByOptionEnum,
  MMLeaderboardFilterByOptionEnumValues,
  RankPresentationOptionEnum,
  RankPresentationOptionEnumValues,
  RankValueOptionEnum,
  RankValueOptionEnumValues,
} from '@forgd/shared/enums'

enum MMRFQParticipationStatuses {
  Selected = 1,
  NotSelected = 2,
  Ignored = 3,
}

export const MarketMakerLeaderboardQueryRequest = z.object({
  filter: z.enum(MMLeaderboardFilterByOptionEnumValues).default(MMLeaderboardFilterByOptionEnum.All),
  marketCapCategories: z.array(z.enum(ExchangeMcapTierEnumValues)).optional(),
  exchangeTiers: z.array(z.enum(ExchangeTierEnumValues)).optional(),
  volumeType: z.enum(MarketMakerVolumeTypeEnumValues).optional().default(MarketMakerVolumeTypeEnum.Total),
  liquidityType: z.enum(LiquidityTypeOptionEnumValues).optional().default(LiquidityTypeOptionEnum.BidAsk),
  depthLevel: z.enum(DepthLevelOptionEnumValues).optional().default(DepthLevelOptionEnum.Depth200),
  engagementOption: z.enum(EngagementOptionEnumValues).optional(),
  rankValue: z.enum(RankValueOptionEnumValues).optional().default(RankValueOptionEnum.Dollar),
  rankPresentation: z.enum(RankPresentationOptionEnumValues).optional().default(RankPresentationOptionEnum.Values),
})

export type MarketMakerLeaderboardQuery = z.infer<typeof MarketMakerLeaderboardQueryRequest>

export const MMLeaderBoardItem = z.object({
  rank: z.number(),
  marketMakerId: z.string(),
  marketMakerName: z.string(),
  imageUrl: z.string(),
  projectsTracked: z.number(),
  avgProjectFDV: z.number(),
  tier1: z.number(),
  tier2: z.number(),
  tier3: z.number(),
  engagementOptions: z.array(z.enum(EngagementOptionEnumValues)).min(1).nullable(),
})

const MMLeaderBoardItemByDepth = MMLeaderBoardItem.extend({
  hasOverreported: z.boolean().nullable(),
  avgDollarDepth: z.number().nullable(),
  avgDepthOfTotal: z.number().nullable(),
})

const MMLeaderBoardItemBySpread = MMLeaderBoardItem.extend({
  hasOverreported: z.boolean().nullable(),
  avgSpreadPercent: z.number().nullable(),
})

const MMLeaderBoardItemByVolume = MMLeaderBoardItem.extend({
  hasOverreported: z.boolean().nullable(),
  avgDailyVolume: z.number().nullable(),
  avgVolumeOfTotal: z.number().nullable(),
})

const MMLeaderBoardItemAll = MMLeaderBoardItem.extend({
  depthRank: z.number(),
  volumeRank: z.number(),
  spreadRank: z.number(),
  depthValue: z.number().nullable(),
  volumeValue: z.number().nullable(),
  spreadValue: z.number().nullable(),
})

export const MarketMakerLeaderboardResponse = z.array(
  z.discriminatedUnion('type', [
    MMLeaderBoardItemAll.extend({ type: z.literal('all') }),
    MMLeaderBoardItemByDepth.extend({ type: z.literal('depth') }),
    MMLeaderBoardItemByVolume.extend({ type: z.literal('volume') }),
    MMLeaderBoardItemBySpread.extend({ type: z.literal('spread') }),
  ]),
)

export type MarketMakerLeaderboard = z.infer<typeof MarketMakerLeaderboardResponse>

export { MMRFQParticipationStatuses }
