import { stringToIntNumber } from '@forgd/shared/utils/zod'
import { z } from 'zod'

export const GetMarketplaceLiquidityQuery = z.object({
  date: z.string().date(),
  exchange: z.string().nullish(),
  orderBook: z.string().nullish(),
  daysLookback: z
    .string()
    .optional()
    .nullable()
    .transform(stringToIntNumber)
    .refine((val) => val == null || val > 0, {
      message: 'daysLookback must be a positive integer',
    }),
})

export type GetMarketplaceLiquidityQueryType = z.infer<typeof GetMarketplaceLiquidityQuery>

export const GetMarketplaceLiquidityResult = z.array(
  z.object({
    timestamp: z.string(),
    price: z.number(),
    costToMoveDown: z.number(),
    costToMoveUp: z.number(),
    spread: z.number().nullable(),
  }),
)

export const GetProjectExchangesResult = z.array(
  z.object({
    id: z.string().nullable(),
    name: z.string().nullish(),
    logoUrl: z.string().nullish(),
  }),
)
export type GetProjectExchangesResultType = z.infer<typeof GetProjectExchangesResult>
