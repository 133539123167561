import { z } from 'zod'
import {
  ActivityExecutionTypeEnumValues,
  ActivityReviewIntervalEnumValues,
  ProjectActivityStatusEnumValues,
  ProjectTgeStageEnumValues,
  ToolTypeEnumValues,
} from '@forgd/supabase'

export const PlaybookActivity = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string().nullable(),
  durationEstimateDays: z.number().nullable(),
  executionType: z.enum(ActivityExecutionTypeEnumValues).nullable(),
  resourceUrl: z.string().nullable(),
  reviewInterval: z.enum(ActivityReviewIntervalEnumValues).nullable(),
  stage: z.enum(ProjectTgeStageEnumValues),
  toolType: z.enum(ToolTypeEnumValues).nullable(),
  toolUrl: z.string().nullable(),
  status: z.enum(ProjectActivityStatusEnumValues),
  order: z.number(),
  depth: z.number(),
  parentId: z.string().nullable(),
  dependsOnActivityId: z.string().nullable(),
})

export const ActivityToolStatusEnum = {
  ReadyForActivation: 'ready_for_activation',
  ActiveAndTracking: 'active_and_tracking',
  RunningDemoData: 'running_demo_data',
} as const

export type ActivityToolStatusEnumType = (typeof ActivityToolStatusEnum)[keyof typeof ActivityToolStatusEnum]

export const basicActivityIds = [
  // Pre TGE
  'design_tokenomics_and_protocol_flows',
  'engage_a_market_maker_pre_tge',
  'exchange_listing_pre_tge',
  'cashflow_analysis_pre_tge',
  'launch_checklist_activity',
  // Post TGE
  'monitor_your_market_maker',
  'engage_a_market_maker_post_tge',
  'exchange_listing_post_tge',
  'token_unlocks_and_sell_pressure',
  'cashflow_analysis_post_tge',
] as const

export type BasicActivityId = (typeof basicActivityIds)[number]

export const PlaybookBasicActivity = PlaybookActivity.extend({
  id: z.enum(basicActivityIds),
  toolStatus: z.nativeEnum(ActivityToolStatusEnum).nullable(),
})

export type PlaybookBasicActivity = z.infer<typeof PlaybookBasicActivity>

export const PlaybookAdvancedActivity = PlaybookActivity.extend({
  status: z.enum([...ProjectActivityStatusEnumValues, 'not_available'] as const),
})

export type PlaybookAdvancedActivity = z.infer<typeof PlaybookAdvancedActivity>

export const PlaybookAdvancedActivityGroup = z.object({
  activityId: z.string(),
  title: z.string(),
  description: z.string().nullable(),
  activities: z.array(PlaybookAdvancedActivity),
})

export type PlaybookAdvancedActivityGroup = z.infer<typeof PlaybookAdvancedActivityGroup>

export const PlaybookActivityResponse = z.object({
  basic: z.array(PlaybookBasicActivity),
  advanced: z.array(PlaybookAdvancedActivityGroup),
})

export type PlaybookActivityResponse = z.infer<typeof PlaybookActivityResponse>

export const PostActivitiesLead = z.object({
  projectId: z.string().uuid(),
  projectName: z.string().trim().min(1),
  firstName: z.string().trim().min(1),
  lastName: z.string().trim().min(1),
  siteUrl: z.string().trim().optional().nullable(),
  email: z.string().email(),
  activityId: z.string().optional().nullable(),
})

export type ActivitiesLeadType = z.infer<typeof PostActivitiesLead>

export const GetActivitiesLeadResult = PostActivitiesLead.extend({
  id: z.string().uuid(),
})
