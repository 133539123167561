import { z } from 'zod'
import { KpiTierEnumValues } from '@forgd/shared/liquidity/kpis-table-view'

export const KpiResultSchema = z.object({
  tier: z.enum(KpiTierEnumValues),
  loanValueDepth50Bps: z.number().positive().finite(),
  loanValueDepth100Bps: z.number().positive().finite(),
  loanValueDepth200Bps: z.number().positive().finite(),
  bidAskSpreadBps: z.number().positive().finite(),
})

export const BasicKpiResultSchema = z.object({
  bidAskSpreadTarget: z.number().positive().finite(),
  depth50Target: z.number().positive().finite(),
  depth100Target: z.number().positive().finite(),
  depth200Target: z.number().positive().finite(),
})

export type KpiResult = z.infer<typeof KpiResultSchema>
export type BasicKpiResult = z.infer<typeof BasicKpiResultSchema>
