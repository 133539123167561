import { z } from 'zod'
import { MonthlyDollarDemand, TokenDistributionPreview, TokenEmissionPreview } from './modeling.types'
export const priceVMarketCap = z.object({
  month: z.number(),
  price: z.number(),
  marketCap: z.number(),
})
export type priceVMarketCap = z.infer<typeof priceVMarketCap>
export const priceAndMonth = priceVMarketCap.omit({ marketCap: true })
export type MonthlyPriceData = z.infer<typeof priceAndMonth>
export const postTgePrice = z.object({
  day: z.number(),
  open: z.number(),
  close: z.number(),
  avg: z.number(),
})
export type PostTGEPrice = z.infer<typeof postTgePrice>

export const EstimatedPerformanceSummarySchema = z.object({
  'Circulating Supply (Qty)': z.number(),
  'Cumulative Net Buy/Sell (USD)': z.number(),
  'Fully Diluted Valuation': z.number(),
  'MC vs. FDV as a %': z.number(),
  'Market Cap': z.number(),
  'Market Cap % Change': z.number(),
  'Price % Change': z.number(),
  'Time after TGE': z.string(),
  'Token Price': z.number(),
})

export const Dashboard = z.object({
  tasks: z.array(z.string()),
  tokenProfile: z.number(),
  tokenPublicPage: z.boolean(),
  tokenomicsModeling: z.object({
    distribution: TokenDistributionPreview,
    emission: TokenEmissionPreview.nullable(),
    estimatedMonthlyDemand: MonthlyDollarDemand.nullable(),
  }),
  projectValuation: z.string().nullable(),
  postTgePerformance: z.object({
    monthlySupply: z.string().nullable(),
    circulatingSupply: z
      .array(
        z.object({
          mon_supply_growth: z.number(),
          month: z.number(),
        }),
      )
      .nullable(),
    cumulativeSellPressure: z.array(z.record(z.string(), z.number())).nullable(),
    priceDiscoverySimulation: z.array(priceAndMonth).nullable(),
    postTgePop: z.array(postTgePrice).nullable(),
    priceVMarketCap: z.array(priceVMarketCap).nullable(),
    estimatedPerformanceSummary: z.array(EstimatedPerformanceSummarySchema).nullable(),
  }),
})
export type Dashboard = z.infer<typeof Dashboard>
export type EstimatedPerformanceSummary = z.infer<typeof EstimatedPerformanceSummarySchema>
