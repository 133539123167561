import { z } from 'zod'

export const MonthlySupplyDemandDelta = z.object({
  delta: z.number(),
  demand: z.number(),
  month: z.number(),
  supply: z.number(),
})

export type MonthlySupplyDemandDelta = z.infer<typeof MonthlySupplyDemandDelta>

export const AdjustingPreviewResponse = z.array(
  z.object({
    slug: z.string(),
    data: z.array(MonthlySupplyDemandDelta),
  }),
)

export type AdjustingPreviewResponse = z.infer<typeof AdjustingPreviewResponse>

export const SimulatingPostTGEPop = z.object({
  postTgePopMultiplier: z.number().default(1),
})
export type SimulatingPostTGEPop = z.infer<typeof SimulatingPostTGEPop>

export const EmptyField = z.object({
  empty: z.boolean().default(true),
})
export type EmptyField = z.infer<typeof EmptyField>

export const AdjustingFieldsSchema = z.union([SimulatingPostTGEPop, EmptyField])

export type AdjustingFieldsSchema = z.infer<typeof AdjustingFieldsSchema>

export const PutAdjustingBody = z.object({
  subSectionId: z.string(),
  fields: AdjustingFieldsSchema,
})
