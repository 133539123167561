import {
  EngagementOptionEnumValues,
  MarketMakerEngagementsStatusEnumValues,
  MarketMakerKpiTypeEnum,
} from '@forgd/supabase'
import { z } from 'zod'
import { BasicKpiResultSchema, KpiResultSchema } from '../kpi'

// TODO: move to shared types when the time comes
const ProjectSchema = z.object({
  id: z.string().uuid(),
  image: z.string().nullable(),
  name: z.string(),
  ticker: z.string().nullable(),
})

const EngagementDetailsSchema = z.object({
  id: z.string().uuid(),
  firstEngagementDate: z.date().nullable(),
  lastEngagementDate: z.date().nullable(),
  totalEngagements: z.number().nullable(),
  status: z.enum(MarketMakerEngagementsStatusEnumValues),
})

const EngagementSummarySchema = z.object({
  project: ProjectSchema,
  engagement: EngagementDetailsSchema,
})

const EngagementSchema = z.object({
  id: z.string().uuid(),
  projectId: z.string().uuid(),
  coingeckoId: z.string().min(1, 'Coingecko ID is required'),
  type: z.enum(EngagementOptionEnumValues),
  loanTokenQty: z.number(),
  loanStablecoinQty: z.number().nullable(),
  generalUptimePercentage: z.number().nullable(),
  kpiOrderBookDominanceThreshold: z.number().nullable(),
  status: z.enum(MarketMakerEngagementsStatusEnumValues),
  summary: EngagementDetailsSchema.pick({
    firstEngagementDate: true,
    lastEngagementDate: true,
    totalEngagements: true,
  }),
})

const CreateEngagementSchema = EngagementSchema.pick({
  coingeckoId: true,
  type: true,
  loanTokenQty: true,
  loanStablecoinQty: true,
  generalUptimePercentage: true,
  kpiOrderBookDominanceThreshold: true,
})

const UpdateEngagementSchema = EngagementSchema.pick({
  projectId: true,
  coingeckoId: true,
  type: true,
  loanTokenQty: true,
  loanStablecoinQty: true,
  generalUptimePercentage: true,
  kpiOrderBookDominanceThreshold: true,
})

const createKpiDiscriminatedUnion = <T extends z.ZodRawShape>(baseSchema: z.ZodObject<T, 'strip', z.ZodTypeAny>) => {
  return z.discriminatedUnion('kpiType', [
    baseSchema.extend({
      kpiType: z.literal(MarketMakerKpiTypeEnum.Basic),
      kpis: BasicKpiResultSchema,
    }),
    baseSchema.extend({
      kpiType: z.literal(MarketMakerKpiTypeEnum.Advanced),
      kpis: z.array(KpiResultSchema),
    }),
    baseSchema.extend({
      kpiType: z.literal(null),
      kpis: z.literal(null),
    }),
  ])
}

export const GetMarketMakerEngagementsSummariesResponseBody = z.array(EngagementSummarySchema)

export const GetMarketMakerEngagementResponseBody = createKpiDiscriminatedUnion(EngagementSchema)
export const CreateMarketMakerEngagementRequestBody = createKpiDiscriminatedUnion(CreateEngagementSchema)
export const UpdateMarketMakerEngagementRequestBody = createKpiDiscriminatedUnion(UpdateEngagementSchema)

export type MarketMakerEngagementSummary = z.infer<typeof EngagementSummarySchema>
export type MarketMakerEngagement = z.infer<typeof GetMarketMakerEngagementResponseBody>
export type CreateMarketMakerEngagement = z.infer<typeof CreateMarketMakerEngagementRequestBody>
export type UpdateMarketMakerEngagement = z.infer<typeof UpdateMarketMakerEngagementRequestBody>
