import { parseISO, isValid } from 'date-fns'
import { z } from 'zod'
import { LikertScaleEnum } from '@forgd/shared/enums'

export const likertScaleTypeSchema = z.number().min(LikertScaleEnum.StronglyDisagree).max(LikertScaleEnum.StronglyAgree)

export const customDateSchema = z
  .string()
  .refine(
    (value) => {
      const parsedDate = parseISO(value)
      return isValid(parsedDate)
    },
    {
      message: 'Invalid date format',
    },
  )
  .transform((value) => new Date(value))

export const ProjectPathParam = z.object({
  projectId: z.string().uuid(),
})

export const MarketMakerPathParam = z.object({
  marketMakerId: z.string().min(1),
})

export type ProjectPathParamType = z.infer<typeof ProjectPathParam>
export type MarketMakerPathParamType = z.infer<typeof MarketMakerPathParam>
